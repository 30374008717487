/* General Styles */
body {
  font-family: "Anek Gujarati", sans-serif;
  background-color: #efefef;
  color: #333333;
}

/* SignIn Component */
.login {
  background-color: #333333;
  color: #fff;
  border: 2px solid #fff;
  padding: 10px 15px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login:hover {
  background-color: #333;
}

.login img {
  width: 50px;
  height: 50px;
}

/* General Styling for other components if needed */
button {
  cursor: pointer;
}
